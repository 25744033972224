@import "fonts/mulish.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Common/global settings */
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #001023;
}
html,
body,
input,
button,
select,
option,
textarea,
table,
th,
tr,
td,
tbody,
tfoot,
ul,
li,
ol {
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: #333;
  line-height: 130%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Utility classes */
.p-absolute {
  position: absolute;
}
.p-relative {
  position: relative;
}

.semi-bold {
  font-weight: 600;
}
.align-right {
  text-align: right;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-stretch {
  align-items: stretch;
}
.flex-grow {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-shrink {
  flex-shrink: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

/* Generic card styles */
.card {
  margin: 0.5em;
  background: #ffffff;
  border: 1px solid #d0d8dd;
  border-radius: 8px;
}

.card .header {
  padding: 20px 24px 12px 24px;
  border-bottom: 1px solid #d0d8dd;
}

.card .header .title {
  align-self: center;
  font-weight: 600;
  font-size: 1.2em;
  color: #458ca2;
}
.card .body {
  padding: 1em;
}

.paragraph-3 {
  font-size: 18px;
  color: #005f7c;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.paragraph-2 {
  font-size: 16px;
  color: #323f46;
  order: 1;
  flex-grow: 0;
}
