.add-user-icon {
  display: flex;
  width: 33px;
  height: 33px;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.add-user-icon:hover {
  cursor: pointer;
  background-color: #384252;
}

.add-user-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 12px;
}

.form-group {
  flex: 1;
  margin-right: 10px;
  display: hidden;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-bolder);
  letter-spacing: 0;
  line-height: 20px;
  margin: 5px 0;
  text-align: left;
}

.form-group input {
  color: #fff;
  width: 90%;
  padding: 10px 14px 10px 14px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #3b4656;
  background-color: #001023;

  &:disabled {
    background-color: #253040;
    color: #fff;
    cursor: not-allowed;
    border: 1px solid #3b4656;
    opacity: 0.75;
  }
}

.add-user-button {
  background: #005f7c;
  border: 1px;
  border-radius: 8px;
  color: #fff;
  gap: 8px;
  height: 48px;
  padding: 8px 30px;
  width: 126px;
  margin-left: auto;
}

.add-user-button:hover {
  cursor: pointer;
}

.add-user-button:disabled {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.6;
}

.cancel-button {
  background: #005f7c;
  border: 1px;
  border-radius: 8px;
  color: #fff;
  height: 48px;
  padding: 8px 30px;
  width: 126px;
}

.cancel-button:hover {
  cursor: pointer;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.input-error {
  border: 1px solid red !important;
}

.form-group-inline {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }

  .checkbox-input {
    margin-right: 90%;
  }
}

.form-group-password-check {
  margin-top: 8px;
  display: flex;

  .checkbox-input {
    width: 4% !important;
  }
}

.flex-row {
  display: flex;
  align-items: center;
}

.modal-overlay {
  z-index: 1000;
}

.change-password-button {
  background: #005f7c;
  border: 1px;
  border-radius: 8px;
  color: #fff;
  padding: 8px 30px;
  width: 90%;
  padding: 10px 14px 10px 14px;
  gap: 8px;
  margin-top: 25px;
}

.change-password-button:disabled {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.6;
}

.change-password-button:hover {
  cursor: pointer;
}

.settings-form-group {
  flex: 1;
}

.required-asterisk {
  color: red;
  margin-left: 0.3rem;
}

.mandatory-fields-message {
  font-size: 14px;
  color: #888;
}

.form-mandatory-message {
  display: flex;
  justify-content: space-between;
}

.alert-icon-container {
  display: flex;
}

.form-user-roles {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 90px;
}

.form-change-password-button {
  width: 50%;
}
