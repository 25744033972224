.add-customer-button {
  background: #005f7c;
  border: 1px;
  border-radius: 8px;
  color: #fff;
  gap: 8px;
  height: 48px;
  padding: 8px 30px;
  width: 126px;
  margin-left: auto;
}

.add-customer-button:hover {
  cursor: pointer;
  background-color: #384252;
}

.add-customer-name-button {
  background: #005f7c;
  border: 1px;
  border-radius: 8px;
  color: #fff;
  padding: 8px 30px;
}

.add-customer-name-button:hover {
  cursor: pointer;
}

.add-customer-button:disabled {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.6;
}
