.dropdown .groupDropdown-btn {
  display: flex;
  padding: 0px 14px;
  height: 40px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-100, #3b4656);
  background: var(--darkmode-800, #001023);
  color: #fff;
  justify-content: space-between;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  width: 90%;
}

.groupDropdown-input {
  display: block;
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-bolder);
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}

.group-name {
  display: flex;
  padding: 0px 6px;
  height: 35px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  cursor: pointer;
}

.group-name .item {
  cursor: pointer;
}

.group-name .item:hover {
  background: #458ca2;
}

.group-name .item-selected {
  cursor: pointer;
  background: #dcf1f7;
}
