@import "../../styles/global.scss";

.navigation-bar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  height: 100vh;
  left: 0;
  top: 0;
  background: #253040;
  float: left;
}

.navigation-bar-items {
  min-width: 416px;
  height: 100vh;
  gap: 16px;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navigation-bar-items-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 36px 16px 16px 16px;
}

.navigation-bar-top-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  min-width: 68px;
  height: -webkit-fill-available;
}

.navigation-bar-navigation-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  justify-self: flex-start;
}

.navigation-bar-logo-image-container {
  margin-bottom: 33px;
}

.navigation-bar-logo-image {
  width: 40px;
  height: 50.256px;
}

.navigation-bar-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.navigation-bar-navigation-image-container {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background-color: #384252;
  }
}

.active-link {
  background-color: #384252;
}
