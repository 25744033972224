.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #131f2e;
  border: 1px solid #3b4656;
  color: #f7f9f9;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 740px;
  max-width: 80%;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 1000;
}
