.table {
  width: 99%;
  border-collapse: collapse;
  overflow: auto;
  border-radius: 8px !important;
}
.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-collapse: collapse;
  border-bottom: 1px solid #3b4656;
  color: #f7f9f9;
  background-color: #111e30;
}

.table th {
  background: #253040;
  color: #f7f9f9;
  font-weight: bold;
  font-size: 15px;
}

.table td:hover {
  background: #253040;
  cursor: pointer;
}

.table tr:nth-child {
  background: #253040;
  color: var(--Neutral-Grey-25, #f7f9f9);
}

.theme-dark {
  background-color: #001023;
}

.table-container {
  margin-left: 85px;
  overflow-y: auto;
}

.main-header {
  display: flex;
  height: 72px;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: #131f2e;
}

.main-content {
  margin: 0 10px 0 85px;
  .table-container {
    border-radius: 8px;
    border: 1px solid #3b4656;
  }
  .table-container,
  .table-header-items {
    margin: 0;
    .table {
      width: 100%;
      thead {
        th:first-child {
          border-radius: 8px 0 0 0;
        }
        th:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
  }
}

.header-name {
  color: #fff;
  font-family: Mulish;
  font-size: 23px;
  font-weight: var(--font-weight-bold);
  line-height: 28px;
  letter-spacing: -0.92px;
}

.pagination {
  display: flex;
  justify-content: center;
  font-size: 10px;
  padding: 10px;
  color: #fff;
}

.pagination-button {
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  background: #005f7c;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.pagination-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.pagination-info {
  align-self: center;
  font-size: 1.2em;
  font-weight: bold;
}

.search-bar {
  padding: 2px 10px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #3b4656;
  border-radius: 4px;
  background-color: #111e30;
  color: #f7f9f9;
}

.table-header-items {
  display: flex;
  padding: 20px 0px;
  justify-content: end;
  gap: 12px;
  margin-right: 1%;
}

.table-header-cell {
  height: 85px;
  align-items: center;
  border-radius: 4px 0px 0px 0px;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
}

.table-header-item {
  align-items: center;

  .sort-image {
    margin-left: 10px;
  }
}

.header-actions {
  display: flex;
  gap: 10px;
}

.download-icon {
  display: flex;
  width: 33px;
  height: 33px;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.download-icon:hover {
  cursor: pointer;
  background-color: #384252;
}
