.select-input {
  color: #fff;
  width: 95%;
  padding: 10px 14px 10px 14px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #d0d8dd;
  margin-bottom: 10px;
  background-color: #001023;
}

.select-input option {
  color: #fff;
}

.checkbox-select-input {
  width: 10% !important;
}

.asset-details-input {
  width: 50% !important;
}

.controller-input {
  width: 95%;
}

.no-customer-found{
  display: flex;
  justify-content: center;
}

.add-new-customer{
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.asset-checkbox:hover{
 cursor: pointer;
}
