// Typography Mixins

/* Paragraph 2 [P2]/Regular */
@mixin paragraph2 {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* Heading 06/Medium */
@mixin heading6 {
  font-family: Mulish;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 121.053% */
  letter-spacing: -0.38px;
}

/* Caption/Bold */
@mixin captionBold {
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 150% */
}
